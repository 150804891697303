import React, { Component } from 'react';
import DashboardDonacion from "./dashboardDonacion";
import Donaciones from "./donaciones";
import Causas from "./causas";
import AdminCausas from "./admincausas";
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
import {formatCurrency} from "../utils/commonFunctions";

class DonationHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'Dashboard',
        }

        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    render() {
        return (
            <div className="dashboard-banking">

                <div className="p-grid overview-boxes">
                    <div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('Dashboard')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout"
                                     className="icon-transfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Dashboard</span>

                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('Donations')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-creditcards.png"
                                     alt="avalon-layout"
                                     className="icon-creditcards"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Donations Report</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('Causes')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-cardtransfer.png"
                                     alt="avalon-layout"
                                     className="icon-cardtransfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Causes Report</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('Admin_Causes')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-cardtransfer.png"
                                     alt="avalon-layout"
                                     className="icon-cardtransfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Admin Causes</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                {(this.state.opcionMenuSelected==='Dashboard') && <DashboardDonacion />}
                {(this.state.opcionMenuSelected==='Donations') && <Donaciones />}
                {(this.state.opcionMenuSelected==='Causes') && <Causas />}
                {(this.state.opcionMenuSelected==='Admin_Causes') && <AdminCausas />}
            </div>
        );
    }
}

export default DonationHome;
