import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {PaymentService} from "../../service/payment/PaymentService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

class Apis extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "",
            listMechants : [],
            listPayments: [],
            catBanks:[],
            bankId:""
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
    }

    initCatalogos(){

        this.apiHeisenberg = new HeisenbergService()

        let usuarioSession = sessionStorage.getItem("usuarioSesion");
        console.log('Merchant '+usuarioSession);


    }

    callListPrincipal(){
        this.apiPayments = new PaymentService()
        let params = {merchantKey:this.state.merchantId,date_ini:'123',date_end:'123'}

        this.apiPayments.getPayments(params).then(resp => {
            if(resp.status === 'success'){
                let listPayments = resp.data
                console.log('status '+resp.status)
                this.setState({listPayments});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }


    render() {
        return(
            <div className="pageWrapper" >
                <Card>
                    <CardTitle>Merchant</CardTitle>

                    <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {this.setState({merchantId: e.value})}} placeholder="Select Merchant"/>
                    <Button label='Search' onClick={this.callListPrincipal} />

                    <DataTable value={this.state.listPayments} selectionMode="single" header="Payments" resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.data})}>
                        <Column field="uuid" header="Id" sortable={true}/>
                        <Column field="merchant_label" header="Merchant" sortable={true}/>
                        <Column field="type_transfer_label" header="Type Transfer" sortable={true}/>
                        <Column field="status_transfer" header="Status Transfer" sortable={true}/>
                        <Column field="credit_card_no" header="Credit Card" sortable={true}/>
                        <Column field="holder_first_name" header="Holder Name" sortable={true}/>
                        <Column field="amount_currency" header="amount" sortable={true}/>

                    </DataTable>
                    <div className="p-grid p-justify-end" style={{ marginTop: '80px'}}>
                        <Button label='Search' onClick={this.callListPrincipal} />
                    </div>
                </Card>
            </div>
        );
    }
}

export default Apis;
