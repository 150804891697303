import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Growl} from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Toolbar} from "primereact/toolbar";

class Received extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listPayments: [],
            catBanks:[],
            bankId:"",
            globalFilter: null
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.loadMerchants = this.loadMerchants.bind(this);
    }

    componentDidMount() {
        this.loadMerchants();
        this.callListPrincipal();
    }

    loadMerchants(){


        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });


    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        let params = {
            "date_ini":"",
            "date_end":"",
            "payment_method":"",
            "payment_type":"",
            "keyword":"",
            "status_payment":"",
            "account_merchant":""
        }

        this.usuarioService.postReportBtcReceived(params).then(resp => {
            if(resp.status === 'success'){
                const listPayments = resp.data
                this.setState({listPayments});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }



    render() {

        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Dashboard BTC</CardTitle>
                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {this.setState({merchantId: e.value})}} placeholder="Select Merchant"/>
                        <Button className="ml-10" label='Search' onClick={this.callListPrincipal} />
                    </Toolbar>

                    <DataTable value={this.state.listPayments} selectionMode="multiple"
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               paginator={true} rows={30}
                               resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="multiple" style={{width:'1em'}}/>
                        <Column field="address" header="Address" sortable={true} style={{width:'20em'}}/>
                        <Column field="invoice_number" header="Invoice Number" sortable={true} className="ta-right"/>
                        <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                        <Column field="received_amount" header="Received Amount" sortable={true} className="ta-right"/>
                        <Column field="unconfirmed" header="Unconfirmed" sortable={true} className="ta-right"/>
                        <Column field="confirmations" header="Confirmations" sortable={true} className="ta-right"/>
                        <Column field="fee_amount" header="Fee" sortable={true} className="ta-right"/>
                        <Column field="processed_date" header="Processed Date" sortable={true} className="ta-center"/>
                        <Column field="dcreated" header="Created" sortable={true} className="ta-center"/>
                        <Column field="time" header="Time" sortable={true} className="ta-right"/>

                    </DataTable>
                    <div className="p-grid p-justify-end" style={{ marginTop: '80px'}}>
                        <Button label='Search' onClick={this.callListPrincipal} />
                    </div>
                </Card>
            </div>
        );
    }
}

export default Received;
