import React from 'react'
import PropTypes from 'prop-types';

const SubMenuItem = ({ children, ...rest }) => {
  return (
    <div className="ui-g-2 subMenuItem">
      <button {...rest}>
        {children}
      </button>
    </div>
  )
}

SubMenuItem.defaultProps = {};

SubMenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default SubMenuItem;
