import React from 'react'
import PropTypes from 'prop-types';

const CardTitle = ({ className, fontSize, children, ...rest }) => {
  return (
    <div className="p-grid mb-20">    
        <div className="ui-g-12">
          <h2 className={`heading ${className}`}>{children}</h2>
        </div>
    </div>
  )
}

CardTitle.defaultProps = {
  fontSize: '24px',
  className: ''
};

CardTitle.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default CardTitle;
