import React, { Component } from 'react';
import Card from '../shared/Card';
import {Button} from "primereact/components/button/Button";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import CardTitle from "../shared/Card/Title";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import { Chart } from "primereact/components/chart/Chart";
import {PaymentService} from "../../service/payment/PaymentService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

export class ReportTypeTransfer extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.apiPayments = new PaymentService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "",
            listMechants: [],
            lstTypeTransfer: [],
            selection: [],
            visible: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let usuarioSession = sessionStorage.getItem("usuarioSesion");
        let merchantParse = JSON.parse(usuarioSession);
        console.log('Merchant '+merchantParse.merchant.name);

        this.apiHeisenberg.getAllMerchants().then(resp => {
            if(resp.status === 'success'){
                let listApisx = resp.data
                let listMechants=[]
                console.log('status '+resp.status)
                listApisx.map(function (elem) {
                    if(merchantParse.merchant.name == elem.key){
                        listMechants.push({label:elem.label,value:elem.key})
                    }
                })
                this.setState({listMechants});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    callListPrincipal(){
        this.apiPayments = new PaymentService()
        let params = {merchantKey:this.state.merchantId,date_ini:'123',date_end:'123'}
        this.apiPayments.getReportTypeTransfer(params).then(resp => {
            if(resp.status === 'success'){
                let lstTypeTransfer = resp.data

                let listLabels=[]
                let listAmounts=[]
                lstTypeTransfer.map(function (elem) {
                    listLabels.push(elem.type_transfer_label)
                })

                lstTypeTransfer.map(function (elem) {
                    listAmounts.push(elem.amount__sum)
                })

                let data = {
                    labels: listLabels,
                    datasets: [
                        {
                            data: listAmounts,
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ],
                            hoverBackgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ]
                        }]
                };

                this.setState({data});
                this.setState({lstTypeTransfer});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });



    }

    render() {



        return(
            <div className="pageWrapper" >
                <Card>
                    <CardTitle>Report by Type Transfer</CardTitle>
                    <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {this.setState({merchantId: e.value})}} placeholder="Select Merchant"/>
                    <Button label='Search' onClick={this.callListPrincipal} />
                    <div className="content-section implementation">
                        <Chart type="pie" data={this.state.data} height={60}/>
                    </div>


                    <div className="p-grid p-justify-end" style={{ marginTop: '200px'}}>
                        <DataTable value={this.state.lstTypeTransfer} selectionMode="single" header="Type Transfer" resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.data})}>
                            <Column field="merchant" header="Merchant" sortable={true}/>
                            <Column field="type_transfer" header="Type Transfer" sortable={true}/>
                            <Column field="amount_currency" header="Amount" sortable={true}/>

                        </DataTable>
                    </div>
                </Card>

            </div>
        );
    }
}

export default ReportTypeTransfer;


