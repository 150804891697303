import React, { Component } from 'react';
import Card from '../shared/Card';
import {Button} from "primereact/components/button/Button";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import CardTitle from "../shared/Card/Title";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import { Chart } from "primereact/components/chart/Chart";
import {PaymentService} from "../../service/payment/PaymentService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

export class ReportGlobalTypeTransfer extends Component {

    constructor(){
        super();
        this.state ={
            data : new FormData(),
            fileName : "",
            typeTransferId : "",
            listMechants: [],
            lstGlobalTypeTransfer: [],
            selection: [],
            visible: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()
    }

    callListPrincipal(){

        this.apiPayments = new PaymentService()
        let params = {typeTransfer:this.state.typeTransferId}
        this.apiPayments.getReportGlobalTypeTransfer(params).then(resp => {
            if(resp.status === 'success'){
                let lstGlobalTypeTransfer = resp.data

                let listLabels=[]
                let listAmounts=[]
                lstGlobalTypeTransfer.map(function (elem) {
                    listLabels.push(elem.merchant)
                })

                lstGlobalTypeTransfer.map(function (elem) {
                    listAmounts.push(elem.amount__sum)
                })

                let data = {
                    labels: listLabels,
                    datasets: [
                        {
                            data: listAmounts,
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ],
                            hoverBackgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ]
                        }]
                };

                this.setState({data});
                this.setState({lstGlobalTypeTransfer});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    render() {


        const lstTypeTransfer = [{label:'VAT',value:"VAT"},{label:'VATT',value:"VATT"},{label:'VRF',value:"VRF"}]

        return(
            <div className="pageWrapper" >
                <Card>
                    <CardTitle>Report Global Type Transfer</CardTitle>
                    <Dropdown value={this.state.typeTransferId} options={lstTypeTransfer} onChange={(e) => {this.setState({typeTransferId: e.value})}} placeholder="Select Type Transfer"/>
                    <Button label='Search' onClick={this.callListPrincipal} />
                    <div className="content-section implementation">
                        <Chart type="pie" data={this.state.data} height={60}/>
                    </div>


                    <div className="p-grid p-justify-end" style={{ marginTop: '200px'}}>
                        <DataTable value={this.state.lstGlobalTypeTransfer} selectionMode="single" header="Global Type Transfer" resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.data})}>
                            <Column field="merchant" header="Merchant" sortable={true}/>
                            <Column field="type_transfer" header="Type Transfer" sortable={true}/>
                            <Column field="amount__sum" header="Amount" sortable={true}/>

                        </DataTable>
                    </div>
                </Card>
            </div>
        );
    }
}

export default ReportGlobalTypeTransfer;
