import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {PaymentService} from "../../service/payment/PaymentService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import TextBold from "../shared/Typography/TextBold";
import Text from "../shared/Typography/Text";
import Heading from "../shared/Typography/Heading";
import TextSemiBold from "../shared/Typography/TextSemiBold";
import {InputSwitch} from "primereact/components/inputswitch/InputSwitch";
import {Chart} from "primereact/components/chart/Chart";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {formatCurrency, formatNumber} from "../utils/commonFunctions";
import { VectorMap } from "react-jvectormap";
import {Calendar} from "primereact/calendar";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import ReactCountryFlag from "react-country-flag";
import {Toolbar} from "primereact/toolbar";
import {Growl} from "primereact/growl";

class DashboardDonacion extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "",
            listMechants: [],
            listAddress: [],
            rates: [],
            catBanks: [],
            bankId: "",
            balanceDonaciones: 0.0,
            balanceCausas: 0.0,
            balanceFee: 0.0,
            balanceGift: 0.0,
            balanceRecurrente: 0.0,
            balancePayment: 0.0,
            balanceRefund: 0.0,
            balanceWeek: 0.0,
            balanceBiWeek: 0.0,
            balanceMonthly: 0.0,
            header: {
                temple: 100.95,
                gifts: 250.25,
                causes_helps: 650.56,
                recurring: 1740.26
            },
            donation_date: '14 de octubre del 2020, 10:45 PM',
            donation_period: null,
            totalBox1: {
                disponible: 1000,
                por_acreditar: 2000,
                transferido: 3000,
                reserva: 4000,
                registrados: 5000
            },

            dataTableValue: [],
            monthlyDataset: {},
            mapCountryData: {},
            arratCountryData: [],
            lastDonations: [],
        }
        this.carService = new CarService();

        this.initCatalogos = this.initCatalogos.bind(this);
        this.getBalanceNode = this.getBalanceNode.bind(this);
        this.getReportSumary = this.getReportSumary.bind(this);
        this.getReportCountry = this.getReportCountry.bind(this);
        this.getReportGralPay = this.getReportGralPay.bind(this);
        this.getReportTotalUsers = this.getReportTotalUsers.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.getBalanceNode();
        this.carService.getCarsMedium().then(data => this.setState({dataTableValue: data}));
        this.getReportSumary();
        this.getReportCountry();
        this.getReportGralPay()
        this.getReportTotalUsers()
        this.callReset();
    }

    getBalanceNode(){

        this.usuarioService= new UsuarioService();
        let params={month:'*'}
        this.usuarioService.postReportGeneralDonations(params).then(resp => {
            if(resp.status === 'success'){
                console.log("getBalanceNode");
                const dictGeneral=resp.data.total_donations;
                const dictCausas=resp.data.total_causas;
                const dictGift=resp.data.total_gift;
                const dictRecurrente=resp.data.total_recurrent;

                const dictWeek=resp.data.total_week;
                const dictBiWeek=resp.data.total_biweek;
                const dictMonthly=resp.data.total_monthly;

                console.log(JSON.stringify(dictGeneral));
                console.log(JSON.stringify(dictCausas));

                this.setState({
                    balanceDonaciones: dictGeneral['total_amount'],
                    balanceCausas: dictCausas['total_amount'],
                    balanceGift: dictGift['total_amount'],
                    balanceRecurrente: dictRecurrente['total_amount'],
                    balanceRefund: 0.0,
                    balanceWeek: dictWeek['total_amount'],
                    balanceBiWeek: dictBiWeek['total_amount'],
                    balanceMonthly: dictMonthly['total_amount'],
                });

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReportTotalUsers(){

        this.usuarioService= new UsuarioService();

        this.usuarioService.getReportTotalUsersDonations().then(resp => {
            if(resp.status === 'success'){
                console.log("getBalanceNode");
                const total_users=resp.data.total_users;
                this.setState({total_users});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }


    getReportGralPay(){

        this.usuarioService= new UsuarioService();

        this.usuarioService.getReportGeneralPaymentDonations().then(resp => {
            if(resp.status === 'success'){
                console.log("getBalanceNode");
                const total_fees=resp.data.total_fees;
                const total_be_credit=resp.data.total_be_credit;
                const total_transfered=resp.data.total_transfered;
                const total_reserve=resp.data.total_reserve;
                const total_fees_refund=resp.data.total_fees_refund;

                this.setState({total_fees});
                this.setState({total_be_credit});
                this.setState({total_transfered});
                this.setState({total_reserve});
                this.setState({total_fees_refund});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReportSumary(){

        this.usuarioService= new UsuarioService();

        this.usuarioService.getReportSumaryDonations().then(resp => {
            if(resp.status === 'success'){
                let monthlyDataset = resp.data
                this.setState({monthlyDataset});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


        this.usuarioService.getLastDonations().then(resp => {
            if(resp.status === 'success'){
                const lastDonations = resp.data;
                lastDonations.forEach(element => {
                    element.amount_total = formatCurrency(element.amount_total);
                    element.fee_total = formatCurrency(element.fee_total);
                    if(element.merchant_user_desc == null){
                        element.userDesc = 'Guest';
                    } else {
                        element.userDesc = element.merchant_user_desc.username;
                    }
                });

                this.setState({lastDonations});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getReportCountry(){

        this.usuarioService= new UsuarioService();

        this.usuarioService.getReportCountryDonations().then(resp => {
            if(resp.status === 'success'){
                const mapCountryData = resp.data.map;
                const arratCountryData = resp.data.array;
                arratCountryData.forEach(element => {
                    element.total_amount = formatCurrency(element.total_amount);
                });

                this.setState({mapCountryData});
                this.setState({arratCountryData});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    initCatalogos(){

        this.apiHeisenberg = new HeisenbergService()

        this.apiHeisenberg.getAllMerchants().then(resp => {
            if(resp.status === 'success'){
                let listApisx = resp.data
                let listMechants=[]
                console.log('status '+resp.status)
                listApisx.map(function (elem) {
                    listMechants.push({label:elem.label,value:elem.key})
                })
                this.setState({listMechants});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    callSearch = () => {
        const {
            donation_period
        }=this.state;
        if(donation_period !== null){
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const params = {month: monthNames[donation_period.getMonth()]};
            this.apiHeisenberg = new HeisenbergService()
            this.apiHeisenberg.postReportDonationsGeneral(params).then(resp => {
                let msg;
                if(resp.status === 'success'){
                    this.setState({
                        balanceDonaciones: resp.data.total_donations.total_amount,
                        balanceGift: resp.data.total_causas.total_amount,
                        balanceCausas: resp.data.total_gift.total_amount,
                        balanceRecurrente: resp.data.total_recurrent.total_amount
                    });
                    msg = { severity: 'success', summary: 'Success', detail: 'Complete search'};
                }else{
                    msg = { severity: 'error', summary: 'ERROR', detail: 'error in service'};
                }
                this.growl.show(msg);
            }).catch(error => {
                const msg = { severity: 'error', summary: 'ERROR', detail: error.message};
                this.growl.show(msg);
            });
        } else {
            const msg = { severity: 'error', summary: 'ERROR', detail: 'Select a month'};
            this.growl.show(msg);
        }
    }

    callReset = () => {
        const params = {month: '*'};
        this.apiHeisenberg = new HeisenbergService()
        this.apiHeisenberg.postReportDonationsGeneral(params).then(resp => {
            let msg;
            if(resp.status === 'success'){
                this.setState({
                    balanceDonaciones: resp.data.total_donations.total_amount,
                    balanceGift: resp.data.total_gift.total_amount,
                    balanceCausas: resp.data.total_causas.total_amount,
                    balanceRecurrente: resp.data.total_recurrent.total_amount,
                    balanceRefund: resp.data.total_refund.total_amount,
                    donation_period: null
                });
                msg = { severity: 'success', summary: 'Success', detail: 'Complete search'};
            }else{
                msg = { severity: 'error', summary: 'ERROR', detail: 'error in service'};
            }
            this.growl.show(msg);
        }).catch(error => {
            const msg = { severity: 'error', summary: 'ERROR', detail: error.message};
            this.growl.show(msg);
        });
    }

    render() {

        const handleClick = (e, countryCode) => {
            console.log(countryCode);
        };

        const { savingViewMore } = this.state;

        // weekly Saving Data
        const weeklySavingData = {
            labels: ['Monthly','Bi Weekly','Weekly'],
            datasets: [
                {
                    data: [this.state.balanceMonthly, this.state.balanceBiWeek,this.state.balanceWeek],
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ]
                }
            ]
        };

        // Monthly Bas Chart Data
        const monthlySavingData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
                     'August', 'September','October', 'November', 'December'],
            datasets: [
                {
                    label: 'Received',
                    backgroundColor: '#42A5F5',
                    data: [65, 59, 80, 81, 56, 55, 40, 80, 81, 56, 55, 40]
                },
                {
                    label: 'Payment',
                    backgroundColor: '#9CCC65',
                    data: [28, 48, 40, 19, 86, 27, 90, 40, 19, 86, 27, 90]
                }
            ]
        };
        const selectorPeriod = (
            <div className="p-grid">
                <div className="p-col-12 p-md-3 p-lg-3">
                    <Calendar readOnly={true} showIcon={true} dateFormat="MM/yy" placeholder="Working Month"
                              view="month" value={this.state.donation_period}
                              onChange={(e) => this.setState({donation_period: e.value})}/>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6">
                    <Button label="Search" className="ml-13" onClick={this.callSearch} />
                    <Button label="Reset" onClick={this.callReset}/>
                </div>
            </div>
        );
        const headersDonation = (
            <div>
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-md-4 p-lg-3">
                        <div className="p-grid overview-box overview-box-1">
                            <div className="overview-box-title">
                                <i className="fa fa-inbox"></i>
                                <span>Temple</span>
                            </div>
                            <div className="overview-box-count ta-right">{formatCurrency(this.state.balanceDonaciones)}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4 p-lg-3">
                        <div className="p-grid overview-box overview-box-2">
                            <div className="overview-box-title">
                                <i className="fa fa-map-pin"></i>
                                <span>Gifts</span>
                            </div>
                            <div className="overview-box-count ta-right">{formatCurrency(this.state.balanceGift)}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4 p-lg-2">
                        <div className="p-grid overview-box overview-box-3">
                            <div className="overview-box-title">
                                <i className="fa fa-folder"></i>
                                <span>Causes</span>
                            </div>
                            <div className="overview-box-count ta-right">{formatCurrency(this.state.balanceCausas)}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4 p-lg-2">
                        <div className="p-grid overview-box overview-box-4">
                            <div className="overview-box-title">
                                <i className="fa fa-user"></i>
                                <span>Recurring</span>
                            </div>
                            <div className="overview-box-count ta-right">{formatCurrency(this.state.balanceRecurrente)}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4 p-lg-2">
                        <div className="p-grid overview-box overview-box-5">
                            <div className="overview-box-title">
                                <i className="fa fa-user"></i>
                                <span>Refund</span>
                            </div>
                            <div className="overview-box-count ta-right">{formatCurrency(this.state.balanceRefund)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return(
            <div className="pageWrapper" >
                <Growl ref={(el) => this.growl = el} />
                {selectorPeriod}
                {headersDonation}
                <div className="p-grid">
                    <div className="p-col-8">
                        <Card height="height-100">
                            <div className="p-grid">
                                <div className="mb-3 p-col-12 ta-left">
                                    <TextSemiBold className="font-16">Total Donations</TextSemiBold>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div>
                                        <Chart type="bar" data={this.state.monthlyDataset} height="63"/>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col-4 p-lg-4 ">
                        <Card height="height-100" className="cardBox">
                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Total Fees Paid</b>
                                <p className="amount-b-card">{formatCurrency(this.state.total_fees)}</p>
                            </div>
                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Total to be credited</b>
                                <p className="amount-b-card">{formatCurrency(this.state.total_be_credit)}</p>
                            </div>
                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Total Transferred to Wallet</b>
                                <p className="amount-b-card">{formatCurrency(this.state.total_transfered)}</p>
                            </div>
                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Total in Reserve</b>
                                <p className="amount-b-card">{formatCurrency(this.state.total_reserve)}</p>
                            </div>
                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Fees Refunded Transactions</b>
                                <p className="amount-b-card">{formatCurrency(this.state.total_fees_refund)}</p>
                            </div>

                            <div className="card ta-center" style={{marginBottom: 1}}>
                                <b>Registered users</b>
                                <p className="amount-b-card">{this.state.total_users}</p>
                            </div>
                        </Card>
                    </div>
                </div>


                <div className="p-grid">
                    <div className="p-col-8">
                        <Card height="height-100">
                            <VectorMap
                                map={"world_mill"}
                                backgroundColor="transparent" //change it to ocean blue: #0077be
                                zoomOnScroll={true}
                                containerStyle={{
                                    width: "100%",
                                    height: "520px"
                                }}
                                onRegionClick={handleClick} //gets the country code
                                containerClassName="map"
                                regionStyle={{
                                    initial: {
                                        fill: "#e4e4e4",
                                        "fill-opacity": 0.9,
                                        stroke: "none",
                                        "stroke-width": 0,
                                        "stroke-opacity": 0
                                    },
                                    hover: {
                                        "fill-opacity": 0.8,
                                        cursor: "pointer"
                                    },
                                    selected: {
                                        fill: "#2938bc" //color for the clicked country
                                    },
                                    selectedHover: {}
                                }}
                                regionsSelectable={true}
                                series={{
                                    regions: [
                                        {
                                            values: this.state.mapCountryData, //this is your data
                                            scale: ["#146804", "#ff0000"], //your color game's here
                                            normalizeFunction: "polynomial"
                                        }
                                    ]
                                }}
                            />


                            <table className="p-datatable-thead">
                                <tr>
                                    <th>Code</th>
                                    <th>Amount</th>
                                </tr>

                                {this.state.arratCountryData.map((row, index) => (
                                    <tr data-index={index}>
                                        <td className="ta-center"><ReactCountryFlag countryCode={row.country_code} /></td>
                                        <td className="ta-right">{row.total_amount}</td>
                                    </tr>
                                ))}

                            </table>


                        </Card>
                    </div>


                    <div className="p-col-4">
                        <Card height="height-100">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Recurrent Causes</CardTitle>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-8">
                                    <div>
                                        <Chart type="pie" data={weeklySavingData} height="450" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="p-col-12">
                        <Card height="height-100">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Causes</CardTitle>
                            </div>
                            <div className="p-grid">

                                <div className="p-col-12">
                                    <DataTable value={this.state.lastDonations} selectionMode="single" header="Last"rows={10}
                                               responsive={true}>
                                        <Column field="type_donation" header="Type" sortable={true} className="ta-right"/>
                                        <Column field="userDesc" header="User" sortable={true}/>
                                        <Column field="amount_total" header="Amount" sortable={true} className="ta-right"/>
                                        <Column field="fee_total" header="Fee" sortable={true} className="ta-right"/>
                                        <Column field="gift" header="Gift" sortable={true} className="ta-right"/>
                                        <Column field="dcreated" header="Created" sortable={true} className="ta-right"/>
                                    </DataTable>
                                </div>
                            </div>
                        </Card>
                    </div>

                </div>
            </div>
        );
    }
}

export default DashboardDonacion;
