import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {formatCurrency} from "../utils/commonFunctions";
import {PaymentService} from "../../service/payment/PaymentService";
import {UsuarioService} from "../../service/login/UsuarioService";

class Transactions extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            dataTableSelectValue: {payment_desc:{}},
            chargedYet: false,
            refundDialog: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTrans().then(resp => {
            if(resp.status === 'success'){
                const listTrans = resp.data

               this.setState({
                   listTrans,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        this.setState({
            code:"",
            operation:"payment",
            displayDialog: true
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            refundDialog: false,
        });
    }
    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }


    render() {
        const header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;

        const refundFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Refund/Disputes" icon="pi pi-check" className="p-button-danger" onClick={this.refundAction} />
            </React.Fragment>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Transactions</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}
                        </div>
                    </div>

                    <Toolbar className="p-mb-4 mb-10">
                        <Button label="Search" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                    </Toolbar>

                    <DataTable value={this.state.listTrans}
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               selectionMode="single" paginator={true} rows={30}
                               resizableColumns={true}
                               selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="transactionIdentifier" header="Id" sortable={true} className="ta-right"/>
                        <Column field="cardToken" header="CardToken" sortable={true}/>
                        <Column field="merchantName" header="Merchant" sortable={true} className="ta-right"/>
                        <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                        <Column field="merchantCountry" header="Country" sortable={true} className="ta-right"/>
                        <Column field="transactionType" header="Type" sortable={true} className="ta-right"/>

                        <Column field="requestedCurrency" header="requestedCurrency" sortable={true} className="ta-right"/>
                        <Column field="authorizationCode" header="authorizationCode" sortable={true} className="ta-right"/>
                        <Column field="programId" header="programId" sortable={true} className="ta-right"/>
                        <Column field="last4ofPAN" header="last4ofPAN" sortable={true} className="ta-right"/>
                        <Column field="response" header="response" sortable={true} className="ta-right"/>
                        <Column field="merchantCategoryCode" header="CategoryCode" sortable={true} className="ta-right"/>

                    </DataTable>



                </Card>
            </div>
        );
    }
}

export default Transactions;
