import axios from 'axios';
import {timeoutNotificationAlertMins} from '../../components/utils/constants';

export class PaymentService {

    constructor(){

    }

    getConfig = (params) => {
        //this.checkSession()
        //let heisenberg_access_token = sessiopinnStorage.getItem("heisenberg_access_token");
        let paymnent_access_token = 'wTc6BISmRSTQnonTsVRBvVfDawws7p';

        return {
            params : params,
            headers : {
                'Authorization': `Bearer ${paymnent_access_token}`,
            }
        };
    }


    /**
     * Api Payments
     */

    getPayments(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/payment01/ReportMechant/date`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportTypeTransfer(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/payment01/ReportMechant/typeTransfer`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportGlobalTypeTransfer(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/payment01/ReportMechant/globalType`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    checkSession = () => {
        let access_token = sessionStorage.getItem("heisenberg_access_token");
        console.log("En checksession")
        console.log(access_token)
        if(access_token) {
            let expires_date = sessionStorage.getItem("expires_date");
            let seconds = (expires_date-Date.now())/1000;
            let minutes = seconds/60;
            //console.log("=0=> session timeout minutes ? = "+ minutes )
            if( minutes < 0 || !window.varOkConnected ) {
                return false;
            } else if( minutes <= `${timeoutNotificationAlertMins}` ) {
                window.showSessionAlert(this.refreshToken);
                return true;
            }
        }else {
            this.loginService()
        }

        return true;
    }

    postDonationRefund(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/refund/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

}
