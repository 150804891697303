import axios from 'axios';

export class UsuarioService {
  getConfig = (params) => {
        let access_token = sessionStorage.getItem('access_token');
        return {
            params : params,
            headers : {
              'Authorization': `Bearer ${access_token}`,
            }
        };
    }


    postConfig = (contentType) => {
        let access_token = sessionStorage.getItem('access_token');
        if(!contentType) {
            contentType = 'application/json';
        }
        return {
            headers : {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': contentType
            }
        };
    }

  getUsuarioLogueo() {
       return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/profile/`,this.getConfig()).then(
           resp => {
               return resp.data;
       });
   }



    postReportHistoryPayment(params) {
      return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/broker/payment/history`,params,this.getConfig()).then(
        resp => {
            return resp.data;
        });
    }

    getLstMerchants() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/merchants/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportFee(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/report/payment/fee/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportFeeDetail(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/report/payment/fee/detail/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcReceived(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/received/history/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcPayment(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/payment/history/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportBtcMonthly() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/payment/montly/sumary/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportBtcLastTransactions() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/received/history/last/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getListExternalBtc() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/list/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getListAuthorizeExternalBtc() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/list/authorize/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postAddExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/add/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postVerificationCodeExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/sns/btc/external/validateCode/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postDeleteExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/delete/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateInvoiceBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/service/btc/external/payment/create/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postPaymentExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/service/btc/external/payment/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportGeneralDonations(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportGeneralPaymentDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/payment/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportTotalUsersDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/info/users/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }




    getReportSumaryDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/payment/montly/sumary/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportCountryDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/country/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getLastDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/donation/list/last/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportDonationsTrans(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/donation/reportes/transactions/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postReportDonationsCausas(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/reportes/causas/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportWebHookTrans(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/dynamodb/transactions/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportWebHookTransSumary() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/dynamodb/sumary/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }




}
