import React, { Component } from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import Card from "../shared/Card";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            birthday: '',
            preferredCurrency: '',
            phone: '',
            phoneType: '',

            country: '',
            countryBirth: '',
            nationality: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            _state: '',
            postCode: '',
            companyName: '',
            companyEmail: '',
            companyRegistration: '',

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
            currencies:[{label: 'Select Currency', value: ''},
                        {label: 'USD', value: 'USD'}]
        };
    }

    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if(profile != undefined && profile != null){
            const dataProfile = profile.profile;
            this.setState({
                firstName: profile.first_name != null ? profile.first_name : '',
                middleName: dataProfile.middle_name != null ? dataProfile.middle_name : '',
                lastName: profile.last_name != null ? profile.last_name : '',
                birthday: dataProfile.bday != null ? dataProfile.bday : '',
                preferredCurrency: dataProfile.preferred_currency != null ? dataProfile.preferred_currency : '',
                phone: dataProfile.phone_number != null ? dataProfile.phone_number : '',
                phoneType: dataProfile.phone_type != null ? dataProfile.phone_type : '',

                country: dataProfile.country != null ? dataProfile.country : '',
                countryBirth: dataProfile.country_birth != null ? dataProfile.country_birth : '',
                nationality: dataProfile.nationality != null ? dataProfile.nationality : '',
                addressLine1: dataProfile.billing_address_line_1 != null ? dataProfile.billing_address_line_1 : '',
                addressLine2: dataProfile.billing_address_line_2 != null ? dataProfile.billing_address_line_2 : '',
                city: dataProfile.billing_city != null ? dataProfile.billing_city : '',
                _state: dataProfile.billing_state != null ? dataProfile.billing_state : '',
                postCode: dataProfile.billing_post_code != null ? dataProfile.billing_post_code : '',
            });

            /*
            const donationServices = new DonationServices();
            donationServices.getCatalogs().then(resp => {
                if (resp.status === 'success') {
                    this.setState({
                        catPhones: resp.catPhones,
                        catCountries: resp.catCountries,
                        catNationalities: resp.catNationalities,
                        catIdTypes: resp.catIdTypes,
                    });
                }
            }).catch(error => {
                // swal("ERROR", error.response.data.messageResponse, "error");
            });
            */
        }
    }
    submitHandler = e => {
        e.preventDefault();
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phone,
            phoneType,
            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,
        } = this.state;
        const profile = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            bday: birthday,
            preferred_currency: preferredCurrency,
            phone_number: phone,
            phone_type: phoneType,
            country: country,
            country_birth: countryBirth,
            nationality: nationality,
            billing_address_line_1: addressLine1,
            billing_address_line_2: addressLine2,
            billing_city: city,
            billing_state: _state,
            billing_post_code: postCode,
        };
        /*
        const donationServices = new DonationServices();
        donationServices.updateProfile(profile).then(resp => {
            if (resp.status === 'success') {
                const usuarioService = new UsuarioService();
                usuarioService.getProfile().then(resp2 => {
                    if (resp2.status == 'success') {
                        localStorage.setItem('profile', JSON.stringify(resp2.data));
                    }
                }).catch(error => {
                    toast.error('Error reload profile');
                });
                toast.success('Profile saved');
            }
        }).catch(error => {
            toast.error('Error save profile');
        });
        */
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phone,
            phoneType,

            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,

            catPhones,
            catCountries,
            catNationalities,
            companyName,
            companyEmail,
            companyRegistration,
        } = this.state;

        return (
            <Card>
                <div className="p-col-12">
                    <h1>Profile</h1>
                </div>
                <div className="p-grid p-fluid">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <h4>First Name</h4>
                                <InputText
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={this.changeHandler}
                                    value={firstName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Middle Name</h4>
                                <InputText
                                    placeholder="Middle Name"
                                    name="middleName"
                                    onChange={this.changeHandler}
                                    value={middleName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Last Name</h4>
                                <InputText
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={this.changeHandler}
                                    value={lastName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Birthday</h4>
                                <InputText
                                    placeholder="yyyy-mm-dd"
                                    name="birthday"
                                    onChange={this.changeHandler}
                                    value={birthday}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Preferred Currency</h4>
                                <Dropdown options={this.state.currencies}
                                          value={preferredCurrency}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Phone</h4>
                                <InputText
                                    placeholder="Phone"
                                    name="phone"
                                    onChange={this.changeHandler}
                                    value={phone}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Phone Type</h4>
                                <Dropdown options={catPhones}
                                          value={phoneType}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Country Birth</h4>
                                <Dropdown options={catCountries}
                                          value={countryBirth}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Nationality</h4>
                                <Dropdown options={catNationalities}
                                          value={nationality}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12">
                                <h2>Billing Address</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Address Line 1</h4>
                                <InputText
                                    placeholder="Address Line 1"
                                    name="addressLine1"
                                    onChange={this.changeHandler}
                                    value={addressLine1}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Address Line 2</h4>
                                <InputText
                                    placeholder="Address Line 2"
                                    name="addressLine2"
                                    onChange={this.changeHandler}
                                    value={addressLine2}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>City</h4>
                                <InputText
                                    placeholder="City"
                                    name="city"
                                    onChange={this.changeHandler}
                                    value={city}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>State</h4>
                                <InputText
                                    placeholder="State"
                                    name="_state"
                                    onChange={this.changeHandler}
                                    value={_state}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Post Code</h4>
                                <InputText
                                    placeholder="Post Code"
                                    name="postCode"
                                    onChange={this.changeHandler}
                                    value={postCode}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Country</h4>
                                <Dropdown options={catCountries}
                                          value={country}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12">
                                <h2>Company</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Name</h4>
                                <InputText
                                    placeholder="Name"
                                    name="companyName"
                                    onChange={this.changeHandler}
                                    value={companyName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Email</h4>
                                <InputText
                                    placeholder="Email"
                                    name="companyEmail"
                                    onChange={this.changeHandler}
                                    value={companyEmail}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Registration</h4>
                                <InputText
                                    placeholder="Registration"
                                    name="companyRegistration"
                                    onChange={this.changeHandler}
                                    value={companyRegistration}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Button label="Submit" style={{marginBottom:'10px'}} onClick={this.submitHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

}
export default Profile;