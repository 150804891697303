import React from 'react'
import PropTypes from 'prop-types';

const Heading = ({ className, children }) => {
  return <h4 className={`heading ${className}`}>{children}</h4>
}

Heading.defaultProps = {
  className: ''
};

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Heading;
