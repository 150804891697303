import React, {Component} from 'react';

export class EmptyPage extends Component {

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                </div>
            </div>
        );
    }
}