export const configLogin = {
    headers : {
      'Content-Type': 'application/x-www-form-urlencoded',
      //'Authorization': 'Basic cmVhY3RXZWJDbGllbnQ6SjdyTGg2NGpuSUtoRzc1cg==',
    }
  };

  export const acepptSimulacion = ".xlsx, .xls";

  export const resourceServerURL = 'http://192.168.1.144:8083';

  export const es = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
    };


  export const bmsPrincipalURL = 'http://192.168.1.144:8083/bms-principal';
  export const bmsAuthorizationURL = 'http://192.168.1.144:8082/bms-authorization';

  export const timeoutNotificationCheckSecs = 60;

  export const timeoutNotificationAlertMins = 10;

  export const minYearForCalendar = 1900;

  export const numberOfYearsAfterNow = 2;

  export const configOauth = {
	  googleClientId : '808626852193-42pa3bunv26qo7d4b5e9vkdtppgvbbl2.apps.googleusercontent.com',
      googleSecret: 'CstvB9x-kZxAX2KAp9vINXTa',
  }

