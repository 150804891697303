import React from 'react'
import PropTypes from 'prop-types';

const Card = ({ className, height, children, ...rest }) => {
  return (
    <div className={`cf-card ${height} ${className}`} {...rest}>{children}</div>
  )
}

Card.defaultProps = {
  height: '',
  className: '',
};

Card.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Card;
