import React from 'react'
import PropTypes from 'prop-types';

const SubMenu = ({ children, ...rest }) => {
  return (
    <div className="cf-card subMenu" {...rest}>
      <div className="p-grid">{children}</div>
    </div>
  )
}

SubMenu.defaultProps = {};

SubMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default SubMenu;
