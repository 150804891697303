import React, {Component} from 'react';
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
import RequestPayment from "./requestPayment";
import Dashboard from "./Dashboard";
import ListInvoicePayments from './listInvoicePayments'

class InvoicePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected: 'RequestPayment',
        }
    }

    componentDidMount() {
    }

    actionMenu = e => {
        this.setState({opcionMenuSelected: e.target.name});
    }

    render() {
        return (
            <div className="pageWrapper">
                <SubMenu>
                    <SubMenuItem id="InvoicePaymentDashboard" name="InvoicePaymentDashboard"
                                 className={this.state.opcionMenuSelected === 'InvoicePaymentDashboard' ? "active" : ''}
                                 onClick={this.actionMenu}>
                        Dashboard
                    </SubMenuItem>
                    <SubMenuItem id="RequestPayment" name="RequestPayment"
                                 className={this.state.opcionMenuSelected === 'RequestPayment' ? "active" : ''}
                                 onClick={this.actionMenu}>
                        Request Payment
                    </SubMenuItem>
                    <SubMenuItem id="ListPayments" name="ListPayments"
                                 className={this.state.opcionMenuSelected === 'ListPayments' ? "active" : ''}
                                 onClick={this.actionMenu}>
                        List Payments
                    </SubMenuItem>
                </SubMenu>

                {(this.state.opcionMenuSelected === 'InvoicePaymentDashboard') && <Dashboard/>}
                {(this.state.opcionMenuSelected === 'RequestPayment') && <RequestPayment/>}
                {this.state.opcionMenuSelected == 'ListPayments' && <ListInvoicePayments/>}
            </div>
        );
    }

}

export default InvoicePayment;