import React, {Component} from 'react';
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
import ReportLevel0 from "./reportLevel0";
import ReportLevel1 from "./reportLevel1";
import ReportLevel2 from "./reportLevel2";
import ReportLevel3 from "./reportLevel3";
import CardTitle from "../shared/Card/Title";

class FeeReportLevel3 extends Component {
    constructor() {
        super();
        this.state = {
            opcionMenuSelected: 'level3',
        };
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.setState({opcionMenuSelected: event.target.name});
    };

    render() {
        return (
            <div className="pageWrapper">
                {/* Sub Menu */}
                <SubMenu>

                    <SubMenuItem id="menu2"
                                 className={this.state.opcionMenuSelected === 'level3' ? "active" : ''} name="level3"
                                 onClick={this.changeHandler}>
                        Report Reseller
                    </SubMenuItem>
                </SubMenu>

                {/* Page Content */}
                {(this.state.opcionMenuSelected === 'level3') && <ReportLevel3/>}

            </div>
        );
    }
}

export default FeeReportLevel3;