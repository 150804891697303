
import React, { Component } from 'react';
import { Panel } from 'primereact/components/panel/Panel';
import { Button } from 'primereact/components/button/Button';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';

class RequestInvoicePayment extends Component {

    constructor() {
        super();

        this.state ={
            day: 'Rome',
        }
    }

    render() {        

        return <div className="ui-g dashboard">
                    <div className="ui-g-12">
                        <Panel header="Request Invoice Payment">
                            <label style={{ fontSize: 14, marginRight: 16, marginLeft: 23 }}>Enter details below and we will send email with simple instructions on how to pay</label>
                            <div className="p-15">
                                <div className="p-grid mb-20" style={{ marginLeft: -30, marginRight: -30 }}>
                                       <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="FirstName" style={{ fontSize: 14, marginRight: 16 }}>First Name:*</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="LastName" style={{ fontSize: 14, marginRight: 16 }}>Last Name:</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="Email" style={{ fontSize: 14, marginRight: 16 }}>Email:*</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="Phone" style={{ fontSize: 14, marginRight: 16 }}>Phone:</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="Payment" style={{ fontSize: 14, marginRight: 16 }}>Payment Amount:*</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="Invoice" style={{ fontSize: 14, marginRight: 16 }}>Invoice Number:</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-g-12 ui-lg-6">
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="Comments" style={{ fontSize: 14, marginRight: 16 }}>Comments:</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <InputTextarea rows={5} cols={30} value={this.state.value} onChange={(e) => this.setState({value: e.target.value})} />
                                                </div>
                                            </div>
                                            <div className="p-grid pl-30 pr-30">
                                                <div className="p-col-3 pt-15">
                                                    <label htmlFor="SelectInvoice" style={{ fontSize: 14, marginRight: 16 }}>Select Invoice File:</label>
                                                </div>
                                                <div className="p-col-9">
                                                    <input type="file" id="myfile" style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                    <label  style={{ fontSize: 14, marginRight: 16 }} />
                                                </div>
                                                <Button label="Submit" className="p-button-info" style={{ height: 36, padding: '0px 30px' }} />
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </Panel>
                    </div>
                </div>
    }
}

export default RequestInvoicePayment;