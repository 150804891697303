import React, { Component } from 'react';
import SubMenu from '../shared/SubMenu';
import SubMenuItem from '../shared/SubMenu/Item';
import ExternalBtc from "./external";
import {Button} from "primereact/button";

class ExternalBtcHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'External',
        }

        this.actionToDashboard = this.actionToDashboard.bind(this);

    }

    actionToDashboard (event) {
        this.setState({opcionMenuSelected: 'External'});
    }

    render() {
        return (
            <div className="pageWrapper">
                <SubMenu>
                    <SubMenuItem id="RequestPayment" name="RequestPayment"
                                 className={this.state.opcionMenuSelected === 'External' ? "active" : ''}
                                 onClick={this.actionMenu}>
                        External BTC
                    </SubMenuItem>
                </SubMenu>

                {(this.state.opcionMenuSelected === 'External') && <ExternalBtc/>}
            </div>
        );
    }
}

export default ExternalBtcHome;
