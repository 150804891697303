import React from 'react'
import PropTypes from 'prop-types';

const TextBold = ({ className, children, ...rest }) => {
  return <p className={`textBold ${className}`} {...rest}>{children}</p>
}

TextBold.defaultProps = {
  className: ''
};

TextBold.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default TextBold;
