// Archivo para la definicion de las equiquetas de los compotentes de la vista
// Se agregan la etiquetas que se desean utilizar por cada modulo o componente
// Se etiquetas se deben de agrupar por modulo el cual definira el nombre de la const
// ejemplo: si el modulo es Nomina, entonces quedara: export const etiquetasNomina = { };

//Etiquetas de uso general del sistema
export const etiquetasSistema = {
  nombreSistema:'BUSINESS MANAGEMENT SYSTEM',
  inicialesSistema:'B M S',
  derechosSistema:' 2019 MAX PLAN TECHNOLOGIES S.A. DE C.V. - TODOS LOS DERECHOS RESERVADOS'
};

//Etiquetas para el Modulo de Login
export const etiquetasLogin = {
  plaza : 'PLAZA',
  usuario : 'USUARIO',
  password : 'CONTRASEÑA',
  ingresar : 'INGRESAR',
  recuperarPassword: 'RECUPERAR CONTRASEÑA'
};


