
import React, { Component } from 'react';
import { Panel } from 'primereact/components/panel/Panel';
// import { TextLabel } from 'primereact/components/textLabel/TextLabel';
// import { InputText } from 'primereact/components/inputtext/Inputtext';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Dialog} from "primereact/components/dialog/Dialog";
import Card from "../shared/Card";
import CardTitle from "../shared/Card/Title";

class TransactionHistory extends Component {

    constructor() {
        super();

        this.state ={
            day: 'Rome',
            account: '1',
            lstTrasactionHistory:[],
            lstMerchants:[],
            dataTableSelectValue:{}
        }
        this.loadHistoryDefault = this.loadHistoryDefault.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.loadMerchants = this.loadMerchants.bind(this);
    }

    componentDidMount() {
        this.loadHistoryDefault();
        this.loadMerchants();
    }

    actionBodyDetail() {
        this.setState({
            code:"",
            operation:"payment",
            displayDialog: true
        });
    }

    loadHistoryDefault(){

        this.usuarioService = new UsuarioService()
        let params = {
            "date_ini":"",
            "date_end":"",
            "payment_method":"",
            "payment_type":"",
            "keyword":"",
            "status_payment":"",
            "account_merchant":""
        }
        this.usuarioService.postReportHistoryPayment(params).then(resp => {
            if(resp.status === 'success'){
                let lstTrasactionHistory = resp.data
                console.log('status '+resp.status)
                this.setState({lstTrasactionHistory});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    loadMerchants(){


        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            lstMerchants: JSON.parse(lstMerchants)
        });


    }

    render(){
        const days = [
            {"id": 1, label: '01', value: '1'},
            {"id": 2, label: '02', value: '2'},
            {"id": 3, label: '03', value: '3'},
            {"id": 4, label: '04', value: '4'},
            {"id": 5, label: '05', value: '5'},
            {"id": 6, label: '06', value: '6'},
            {"id": 7, label: '07', value: '7'},
            {"id": 8, label: '08', value: '8'},
            {"id": 9, label: '09', value: '9'},
            {"id": 10, label: '10', value: '10'},
            {"id": 11, label: '11', value: '11'},
            {"id": 12, label: '12', value: '12'},
            {"id": 13, label: '13', value: '13'},
            {"id": 14, label: '14', value: '14'},
            {"id": 15, label: '15', value: '15'},
            {"id": 16, label: '16', value: '16'},
            {"id": 17, label: '17', value: '17'},
            {"id": 18, label: '18', value: '18'},
            {"id": 19, label: '19', value: '19'},
            {"id": 20, label: '20', value: '20'},
            {"id": 21, label: '21', value: '21'},
            {"id": 22, label: '22', value: '22'},
            {"id": 23, label: '23', value: '23'},
            {"id": 24, label: '24', value: '24'},
            {"id": 25, label: '25', value: '25'},
            {"id": 26, label: '26', value: '26'},
            {"id": 27, label: '27', value: '27'},
            {"id": 28, label: '28', value: '28'},
            {"id": 29, label: '29', value: '29'},
            {"id": 30, label: '30', value: '30'},
            {"id": 31, label: '31', value: '31'},

        ];


        const months = [
            {"id": 1, label: '01', value: '1'},
            {"id": 2, label: '02', value: '2'},
            {"id": 3, label: '03', value: '3'},
            {"id": 4, label: '04', value: '4'},
            {"id": 5, label: '05', value: '5'},
            {"id": 6, label: '06', value: '6'},
            {"id": 7, label: '07', value: '7'},
            {"id": 8, label: '08', value: '8'},
            {"id": 9, label: '09', value: '9'},
            {"id": 10, label: '10', value: '10'},
            {"id": 11, label: '11', value: '11'},
            {"id": 12, label: '12', value: '12'},
        ];

        const years = [
            {"id": 2020, label: '2020', value: '2020'},

        ];

        const paymentMethod = [
            {"id": "AUTH", label: 'Authorize Transaction', value: "AUTH"},
            {"id": "REF", label: 'Refund Transaction', value: "REF"},
            {"id": "PAY", label: 'Payment Transaction', value: "PAY"},


        ];

        const paymentTypes = [
            {"id": "1", label: 'Credit Card', value: "1"},

        ];

        const accounts = [
            {"id": "efa01930-c150-11ea-b0b9-12a81f54dd62", label: "SHOP ODOO", value: "efa01930-c150-11ea-b0b9-12a81f54dd62"},
            {"id": "0ac60252-a5e4-11ea-b0b9-12a81f54dd62", label: "GWALLET", value: "0ac60252-a5e4-11ea-b0b9-12a81f54dd62"},
        ];

        const statusPayments = [
            {"id": "1", label: "SUCCESS", value: "1"},
            {"id": "3", label: "ERROR", value: "3"},
        ];

        let dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;

        const {
            merchant_key,
            lstMerchants,
            listPayments,
            dataTableSelectValue,
        } = this.state;

        return (<div className="pageWrapper">
                    <Card>
                        <CardTitle>Transaction History</CardTitle>
                            <div className="p-15">
                                <div className="p-grid mb-20" style={{ marginLeft: -30, marginRight: -30 }}>
                                    <div className="p-col-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Date From*</label>
                                            </div>
                                            <div className="p-col-9">
                                                <div className="p-grid">
                                                    <div className="p-col-4">
                                                        <Dropdown id="dayIni" optionLabel="label" value={this.state.dayIni} options={days} placeholder="Day" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({dayIni: e.value})}}/>
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="monthIni" optionLabel="label" value={this.state.monthIni} options={months} placeholder="Month" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({monthIni: e.value})}}/>
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="yearIni" optionLabel="label" value={this.state.yearIni} options={years} placeholder="Year" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({yearIni: e.value})}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Date To*</label>
                                            </div>
                                            <div className="p-col-9">
                                                <div className="p-grid">
                                                    <div className="p-col-4">
                                                        <Dropdown id="dayEnd" optionLabel="label" value={this.state.dayEnd} options={days} placeholder="Day" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({dayEnd: e.value})}}/>
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="monthEnd" optionLabel="label" value={this.state.monthEnd} options={months} placeholder="Month" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({monthEnd: e.value})}}/>
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="yearEnd" optionLabel="label" value={this.state.yearEnd} options={years} placeholder="Year" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({yearEnd: e.value})}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Payment Method</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="day" optionLabel="label" value={this.state.paymentMethod} options={paymentMethod} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({paymentMethod: e.value})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                            <label htmlFor="Status" style={{ fontSize: 14, marginRight: 16 }}>Status</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="Status" optionLabel="label" value={this.state.statusPayment} options={statusPayments} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({statusPayment: e.value})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="Type" style={{ fontSize: 14, marginRight: 16 }}>Type</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="Type" optionLabel="label" value={this.state.paymentType} options={paymentTypes} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} onChange={(e) => {this.setState({paymentType: e.value})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="account" style={{ fontSize: 14, marginRight: 16 }}>Merchants</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown value={merchant_key} options={lstMerchants} style={{width: '100%'}}
                                                          onChange={(e) => {
                                                              this.setState({merchant_key: e.value })
                                                          }}
                                                          placeholder="Select a Merchant"/>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="p-col-6">
                                    <Button label="Search" className="p-button-info" style={{ height: 36, padding: '0px 30px' }} onClick={this.loadHistoryDefault}/>
                                    <Button label="Detail" className="p-button-info" style={{ height: 36, padding: '0px 30px' }} onClick={this.actionBodyDetail}/>
                                    <Button label="Refund" className="p-button-info" style={{ height: 36, padding: '0px 30px' }} />
                                </div>
                                <DataTable value={this.state.lstTrasactionHistory} style={{marginBottom: '20px'}} responsive={true} selectionMode="single" paginator={true}
                                           rows={50} resizableColumns={true} reorderableColumns={true} scrollable={true}
                                           selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                    <Column selectionMode="single" style={{width:'3em'}}/>
                                    <Column field="dcreated" header="Created" sortable={true} />
                                    <Column field="time" header="Time" sortable={true} />
                                    <Column field="origin" header="Origin" filter={true} sortable={true} />
                                    <Column field="invoice_number" header="invoice_number" sortable={true} />
                                    <Column field="amount" header="Amount" sortable={true} />
                                    <Column field="fees" header="Fees" sortable={true} />
                                    <Column field="reserve" header="Reserve" sortable={true} />
                                    <Column field="net_amount" header="Net Amount" sortable={true} />
                                    <Column field="status_transfer" header="Status_transfer" sortable={true} filter={true}/>
                                    <Column field="type_transfer" header="Type_transfer" sortable={true} filter={true}/>
                                    <Column field="merchant" header="Merchant" sortable={true} filter={true}/>
                                </DataTable>

                                <Dialog visible={this.state.displayDialog} style={{width: '1000px'}} header="Transaction Information" modal={true} footer={dialogFooterCancel} onHide={() => this.setState({displayDialog: false})}
                                        blockScroll={false}>

                                    <div className="p-grid p-fluid">

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Merchant</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.merchant}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Origin</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.origin}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Transaction No</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.transaction_no}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Transaction Date</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.dcreated}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Transaction Time</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.time}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Payment Type</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.payment_type}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Card Type</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.credit_type}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Amount</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.amount}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Fees</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.fees}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Reserve</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.reserve}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Net Amount</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.net_amount}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Transaction type</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.type_transfer}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Transaction status</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.status_transfer}</label></div>


                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Reference</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.invoice_number}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Result txt</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.result_api}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Card number</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.credit_card_no}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Holder first name</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.holder_first_name}</label></div>

                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Holder last name</label></div>
                                        <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.holder_last_name}</label></div>

                                    </div>
                                </Dialog>

                            </div>
                        </Card>
                </div>);
    }
}

export default TransactionHistory;
