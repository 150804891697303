import React, { Component } from 'react';
import SubMenu from '../shared/SubMenu';
import SubMenuItem from '../shared/SubMenu/Item';
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import Card from "../shared/Card";
import CardTitle from "../shared/Card/Title";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {InputText} from "primereact/components/inputtext/InputText";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";

class ExternalBtc extends Component {

    emptyProduct = {
        id: null,
        merchant_key: '',
        address: '',
        code: '',
        alias: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            merchantId : "*",
            address : "",
            listMechants : [],
            listPayments: [],
            globalFilter: null,
            selectedProducts:[],
            productDialog: false,
            productDialogConfirm: false,
            product: this.emptyProduct,
            merchant_key_inside: '',
            codeConfirmDialog: false,
            deleteDialog: false,
            dataTableSelectValue:{},
        }
        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.loadMerchants = this.loadMerchants.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.openNew = this.openNew.bind(this);

        this.sendCodeConfirm = this.sendCodeConfirm.bind(this);
    }

    componentDidMount() {
        this.loadMerchants();
        this.callListPrincipal();
    }

    loadMerchants(){


        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });


    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        let params = {
            "date_ini":"",
            "date_end":"",
            "payment_method":"",
            "payment_type":"",
            "keyword":"",
            "status_payment":"",
            "account_merchant":""
        }

        this.usuarioService.getListExternalBtc().then(resp => {
            if(resp.status === 'success'){
                let listPayments = resp.data
                console.log('status '+resp.status)
                this.setState({listPayments});
                //this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            productDialog: false,
            productDialogConfirm: false,
            deleteDialog: false,
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    saveProduct = e =>  {
        const {
            address,
            alias,
            merchant_key_inside,
        } = this.state;
        if (address.trim() && alias.trim()) {
            let params={
                merchant_key: merchant_key_inside,
                address: address,
                alias: alias
            }
            this.usuarioService = new UsuarioService();

            this.usuarioService.postAddExternalBtc(params).then(resp => {
                if(resp.status === 'success'){
                    let listPayments = resp.data
                    console.log('status '+resp.status)
                    console.log(resp.data)
                    //this.showSucccess(resp.time_react)
                    this.setState({
                        submitted: false,
                        productDialog: false,
                        product: this.emptyProduct
                    });
                    setTimeout(() => {
                        this.setState({
                            productDialogConfirm: true,
                        })
                    }, 200);

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });

        }
    }

    openNew() {
        this.setState({
            product: this.emptyProduct,
            submitted: false,
            productDialog: true
        });
    }

    openDelete = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id != undefined) {
            this.setState({
                deleteDialog: true
            });
        } else {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to delete'};
            this.growl.show(msg);
        }
    }

    deleteAddressBtc = () => {
        const {
            dataTableSelectValue
        } = this.state;
        let msg = { severity: 'success', summary: 'Ok', detail: ''};
        this.usuarioService = new UsuarioService();
        const params = {
            address_id: dataTableSelectValue.id
        }
        this.usuarioService.postDeleteExternalBtc(params).then(resp => {
            if(resp.status === 'success'){
                this.hideDialog();
                this.callListPrincipal();
                msg.detail = resp.data.msg;
                this.growl.show(msg);
            } else {
                msg.summary = 'Error';
                msg.detail = '';
                msg.severity = 'error';
                this.growl.show(msg);
            }
        }).catch(error => {
        });

    }

    sendCodeConfirm = e => {
        const {
            code,
            merchant_key_inside,

        } = this.state;
        if (code.trim().length > 0) {
            const params = {
                merchant_key: merchant_key_inside,
                phone: sessionStorage.getItem('phone'),
                code: code
            }
            this.usuarioService = new UsuarioService();
            this.usuarioService.postVerificationCodeExternalBtc(params).then(resp => {
                if(resp.status === 'success'){
                    let listPayments = resp.data
                    console.log('status '+resp.status)
                    console.log(resp.data)
                    //this.showSucccess(resp.time_react)

                    this.setState({
                        submitted: false,
                        productDialog: false,
                        productDialogConfirm: false,
                        product: this.emptyProduct
                    });

                    setTimeout(() => {
                        this.callListPrincipal()
                    }, 200);
                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });
        }
    }

    render() {

        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        const productDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveProduct} />
            </React.Fragment>
        );

        const codeConfirmFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.sendCodeConfirm} />
            </React.Fragment>
        );
        const deleteFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Delete" icon="pi pi-check" className="p-button-text" onClick={this.deleteAddressBtc} />
            </React.Fragment>
        );

        const {
            merchant_key_inside,
            address,
            listMechants,
            code,
            alias,
        } = this.state;

        return (

            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>External BTC</CardTitle>
                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {this.setState({merchantId: e.value})}} placeholder="Select Merchant"/>
                        <Button label='Search' onClick={this.callListPrincipal} className="ml-4" />

                        <Button label="New" icon="pi pi-plus" onClick={this.openNew} className="ml-20"/>
                        <Button label="Delete" icon="pi pi-trash" onClick={this.openDelete}/>
                    </Toolbar>

                    <DataTable value={this.state.listPayments} selectionMode="single"
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               paginator={true} rows={30}
                               resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="multiple" style={{width:'2em'}}/>
                        <Column field="id" header="Id" sortable={true} style={{width:'4em', textAlign:'right'}}/>
                        <Column field="alias" header="Alias" sortable={true} style={{width:'10em', textAlign:'right'}}/>
                        <Column field="address" header="Address" sortable={true} style={{width:'25em'}}/>
                        <Column field="status_row" header="Status" sortable={true} style={{textAlign:'center'}}/>
                        <Column field="created" header="Created" sortable={true} style={{textAlign:'right'}}/>
                    </DataTable>

                    <Dialog visible={this.state.productDialog} style={{width: '450px', height: '400px'}}
                            header="Address" modal className="p-fluid" footer={productDialogFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field mb-25">
                            <label htmlFor="merchant_key">Merchant</label>
                            <Dropdown id='merchant_key' value={merchant_key_inside} options={listMechants}
                                      onChange={(e) => {
                                          this.setState({merchant_key_inside: e.value})
                                      }} placeholder="Select Merchant" />
                        </div>
                        <div className="p-field mb-25">
                            <label htmlFor="alias">Alias</label>
                            <InputText id="alias" name="alias" value={alias} onChange={this.handleChange}/>
                            {this.state.submitted && !this.state.product.alias && <small className="p-invalid">Alias is required.</small>}
                        </div>
                        <div className="p-field mb-25">
                            <label htmlFor="address">Address</label>
                            <InputText id="address" name="address" value={address} onChange={this.handleChange}/>
                            {this.state.submitted && !this.state.product.address && <small className="p-invalid">Address is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.productDialogConfirm} style={{width: '450px', height: '200px'}}
                            header="Confirm Code" modal className="p-fluid" footer={codeConfirmFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field">
                            <label htmlFor="code">Code</label>
                            <InputText id="code" name="code" value={code} onChange={this.handleChange}/>
                            {this.state.submitted && !this.state.product.code && <small className="p-invalid">Code is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteDialog} style={{width: '450px', height: '200px'}}
                            header="Confirm Delete" modal className="p-fluid" footer={deleteFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field">
                            <p htmlFor="code">
                                <b>ID: </b> {this.state.dataTableSelectValue.id}
                            </p>
                            <p>
                                <b>ADDRESS: </b> {this.state.dataTableSelectValue.address}
                            </p>
                        </div>
                    </Dialog>
                </Card>
            </div>
        );
    }
}

export default ExternalBtc;
