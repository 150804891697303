import React, {Component} from 'react';
import PropTypes from "prop-types";
import TextSemiBold from "../shared/Typography/TextSemiBold";
import {Chart} from "primereact/components/chart/Chart";
import Card from "../shared/Card";
import { formatCurrency, formatNumber} from "../utils/commonFunctions";

export class Component_box extends Component {
    static defaultProps = {
        boxName: '',
        serieGeneral: [],
        totalBox1: {
            invoicesReceived: 0,
            openInvoicesBTC: 0,
            openInvoices: 0,
            issuedInvoices: 0,
            fees: ''
        }
    }

    static propTypes = {
        boxName: PropTypes.string,
        serieGeneral: PropTypes.array,
        totalBox1: PropTypes.object
    }

    constructor(){
        super();
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const total_invoices_BTC = (
            <div className="p-grid">
                <div className="p-col-8">
                    <Card height="height-100">
                        <div className="p-grid">
                            <div className="mb-3 p-col-12 ta-left">
                                <TextSemiBold className="font-16">Transactions BTC</TextSemiBold>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="mb-15 ta-center">
                                    <TextSemiBold className="font-16">Transactions BTC</TextSemiBold>
                                </div>
                                <div>
                                    <Chart type="bar" data={this.props.serieGeneral} height="65"/>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="p-col-4 p-lg-4 ">
                    <Card height="height-100" className="cardBox">
                        <div className="card ta-center" style={{marginBottom: 1}}>
                            <b>Total Received / Invoices</b>
                            <p className="amount-b-card"> {this.props.totalBox1.invoicesReceived} %</p>
                        </div>
                        <div className="card ta-center" style={{marginBottom: 1}}>
                            <b>Total Open Invoices</b>
                            <p className="amount-b-card">&#8383; {this.props.totalBox1.openInvoicesBTC} </p>
                        </div>
                        <div className="card ta-center" style={{marginBottom: 1}}>
                            <b>Total Open Invoices</b>
                            <p className="amount-b-card">{formatCurrency(this.props.totalBox1.openInvoices)}</p>
                        </div>
                        <div className="card ta-center" style={{marginBottom: 1}}>
                            <b>Quantity Open Invoice</b>
                            <p className="amount-b-card">{formatNumber(this.props.totalBox1.issuedInvoices)}</p>
                        </div>
                        <div className="card ta-center" style={{marginBottom: 1}}>
                            <b>Total Paid Fees</b>
                            <p className="amount-b-card">&#8383;  {this.props.totalBox1.fees}</p>
                        </div>
                    </Card>
                </div>
            </div>
        );
        const confirming_BTC = (
            <div>
                2
            </div>
        );
        const received_BTC = (
            <div>
                3
            </div>
        );
        const transferred_BTC = (
            <div>
                4
            </div>
        );
        return(
            <div>
                {this.props.boxName==='total_invoices_BTC' ? total_invoices_BTC : ''}
                {this.props.boxName==='confirming_BTC' ? confirming_BTC : ''}
                {this.props.boxName==='received_BTC' ? received_BTC : ''}
                {this.props.boxName==='transferred_BTC' ? transferred_BTC : ''}
            </div>
        );

    }
}
