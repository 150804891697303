import React from 'react'
import PropTypes from 'prop-types';

const Text = ({ className, children, ...rest }) => {
  return <p className={`textPrimary ${className}`} {...rest}>{children}</p>
}

Text.defaultProps = {
  className: ''
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Text;
