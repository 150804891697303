import React, { Component } from 'react';
import SubMenu from '../shared/SubMenu';
import SubMenuItem from '../shared/SubMenu/Item';
import Dashboardbtc from "./dashboardbtc";
import Received from "./received";
import ReportPayment from "./payment";

class BtcHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'Dashboard',
        }

        this.actionToDashboard = this.actionToDashboard.bind(this);
        this.actionToReceived = this.actionToReceived.bind(this);
        this.actionToPayment = this.actionToPayment.bind(this);

    }

    actionToDashboard (event) {
        this.setState({opcionMenuSelected: 'Dashboard'});
    }

    actionToReceived (event) {
        this.setState({opcionMenuSelected: 'Received'});
    }

    actionToPayment (event) {
        this.setState({opcionMenuSelected: 'Payment'});
    }

    render() {
        return (
            <div>
                <div className="dashboard-banking">
                    <div className="p-grid overview-boxes">
                        <div className="p-col-12 p-md-4 p-lg-4" onClick={this.actionToDashboard} style={{cursor:'pointer'}}>
                            <div className="overview clearfix">
                                <div className="overview-icon">
                                    <img src="assets/layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout"
                                         width="52" className="icon-transfer"/>
                                </div>

                                <div className="overview-text">
                                    <span className="overview-title">Open</span>
                                    <span className="overview-subtitle">Dashboard</span>

                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4" onClick={this.actionToReceived} style={{cursor:'pointer'}}>
                            <div className="overview clearfix">
                                <div className="overview-icon">
                                    <img src="assets/layout/images/dashboard-banking/icon-creditcards.png"
                                         alt="avalon-layout"
                                         width="52" className="icon-creditcards"/>
                                </div>

                                <div className="overview-text">
                                    <span className="overview-title">BTC</span>
                                    <span className="overview-subtitle">Received</span>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4" onClick={this.actionToPayment} style={{cursor:'pointer'}}>
                            <div className="overview clearfix">
                                <div className="overview-icon">
                                    <img src="assets/layout/images/dashboard-banking/icon-cardtransfer.png"
                                         alt="avalon-layout"
                                         width="52" className="icon-cardtransfer"/>
                                </div>

                                <div className="overview-text">
                                    <span className="overview-title">BTC</span>
                                    <span className="overview-subtitle">Paid Out</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Content */}
                {(this.state.opcionMenuSelected==='Dashboard') && <Dashboardbtc />}
                {(this.state.opcionMenuSelected==='Received') && <Received />}
                {(this.state.opcionMenuSelected==='Payment') && <ReportPayment />}

            </div>
        );
    }
}

export default BtcHome;
