function isAmount(data) {
    const re = /^[0-9]*\.?[0-9]*$/;
    let istrue = false;

    if (data === '' || re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function valueIsNumber(data) {
    const re = /^[0-9]*$/;
    let istrue = false;

    if (data === '' || re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function skipSpace(data) {
    const value = data.replace(/\s/g, '');
    return value;
}

function isValueText(data) {
    const re = /^[a-zA-Z ]*$/;
    let istrue = false;
    if (data === '' || re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateName(name) {
    const regexName = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u);
    return regexName.test(name);
}

function validatePass(pass) {
    const regexPass = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,10}$/);
    return regexPass.test(pass);
}

function validatePhone(phone) {
    if (valueIsNumber(phone)) {
        if (phone.length > 6) {
            return true;
        }
    }
    return false;
}

function formatNumber(number){
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function formatCurrency(number){
    const options = { style: 'currency', currency: 'USD' };
    const formatNumber = new Intl.NumberFormat('en-US', options);
    return formatNumber.format(number);
}

export {
    isAmount,
    valueIsNumber,
    skipSpace,
    isValueText,
    validateEmail,
    validateName,
    validatePass,
    validatePhone,
    formatNumber,
    formatCurrency
};
