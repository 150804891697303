import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

export class AppInlineProfile extends Component {
    static defaultProps = {
        onLogout: null,
        profile: null,
        onHandleClickProfile: null
    }

    static propTypes = {
        onLogout: PropTypes.func.isRequired,
        profile: PropTypes.any.isRequired,
        onHandleClickProfile: PropTypes.func.isRequired
    }
    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
    }
    componentDidMount() {
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return  <div>
                    <div className={classNames('profile', {'profile-expanded': this.state.expanded})}>
                        <button className="p-link" onClick={this.onClick}>
                            <img alt="Profile" className="profile-image" src="assets/layout/images/avatar01.png" />
                            <span className="profile-name">{this.props.profile.userName}</span>
                            <i className="fa fa-fw fa-caret-down"></i>
                            <span className="profile-role">{this.props.profile.area}</span>
                        </button>
                    </div>

                    <ul className="layout-menu profile-menu">
                        <li role="menuitem">
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'} onClick={this.props.onHandleClickProfile}>
                                <i className="fa fa-fw fa-user"></i>
                                <span>Profile</span>
                            </button>
                            <div className="layout-menu-tooltip">
                                <div className="layout-menu-tooltip-arrow"></div>
                                <div className="layout-menu-tooltip-text">Profile</div>
                            </div>
                        </li>
                        <li role="menuitem">
                            <button className="p-link"  tabIndex={this.state.expanded ? null : '-1'} onClick={this.props.onLogout}>
                                <i className="fa fa-fw fa-sign-out"></i>
                                <span>Logout</span>
                            </button>
                            <div className="layout-menu-tooltip">
                                <div className="layout-menu-tooltip-arrow"></div>
                                <div className="layout-menu-tooltip-text">Logout</div>
                            </div>
                        </li>
                    </ul>
                </div>
    }
}