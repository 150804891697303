
import React, { Component } from 'react';
import { Panel } from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';

class PendingWalletSettlementReport extends Component {

    constructor() {
        super();

        this.state ={
            day: 'Rome',
        }
    }

    componentDidMount() {
    }

    render() {
        let transactionHistoryData = [
			{
				"id": 1,
				"account": "Click for Google",
				"date": "http://google.com/",
				"amount": "2017-02-28",
				"invoiceNo": "2017-02-28",
				"paymentMethod": "2017-02-28",
				"type": "2017-02-28",
            },
            {
				"id": 2,
				"account": "Click for Google",
				"date": "http://google.com/",
				"amount": "2017-02-28",
				"invoiceNo": "2017-02-28",
				"paymentMethod": "2017-02-28",
				"type": "2017-02-28",
			},
			{
				"id": 3,
				"account": "Click for Google",
				"date": "http://google.com/",
				"amount": "2017-02-28",
				"invoiceNo": "2017-02-28",
				"paymentMethod": "2017-02-28",
				"type": "2017-02-28",
			}
        ];
        
        const days = [
            {"id": 1, label: 'New York', value: 'NY'},
            {"id": 2, label: 'Rome', value: 'RM'},
            {"id": 3, label: 'London', value: 'LDN'},
            {"id": 4, label: 'Istanbul', value: 'IST'},
            {"id": 5, label: 'Paris', value: 'PRS'}
        ];

        return <div className="ui-g dashboard">
                    <div className="ui-g-12">
                        <Panel header="Pending Wallet Settlement Report">
                            <div className="p-15">
                                <div className="p-grid mb-20" style={{ marginLeft: -30, marginRight: -30 }}>
                                    <div className="p-col-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Date From*</label>
                                            </div>
                                            <div className="p-col-9">
                                                <div className="p-grid">
                                                    <div className="p-col-4">
                                                        <Dropdown id="day" optionLabel="label" value={this.state.day} options={days} placeholder="Day" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="month" optionLabel="label" value={this.state.day} options={days} placeholder="Month" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="year" optionLabel="label" value={this.state.day} options={days} placeholder="Year" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Date To*</label>
                                            </div>
                                            <div className="p-col-9">
                                                <div className="p-grid">
                                                    <div className="p-col-4">
                                                        <Dropdown id="day" optionLabel="label" value={this.state.day} options={days} placeholder="Day" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="month" optionLabel="label" value={this.state.day} options={days} placeholder="Month" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                    <div className="p-col-4">
                                                        <Dropdown id="year" optionLabel="label" value={this.state.day} options={days} placeholder="Year" style={{ minWidth: 'auto', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="day" style={{ fontSize: 14, marginRight: 16 }}>Payment Method</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="day" optionLabel="label" value={this.state.day} options={days} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                            <label htmlFor="Status" style={{ fontSize: 14, marginRight: 16 }}>Status</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="Status" optionLabel="label" value={this.state.day} options={days} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="Type" style={{ fontSize: 14, marginRight: 16 }}>Type</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="Type" optionLabel="label" value={this.state.day} options={days} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="account" style={{ fontSize: 14, marginRight: 16 }}>Account</label>
                                            </div>
                                            <div className="p-col-9">
                                                <Dropdown id="account" optionLabel="label" value={this.state.day} options={days} placeholder="Select" style={{ minWidth: 'auto', width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6">
                                        <div className="p-grid pl-30 pr-30">
                                            <div className="p-col-3 pt-15">
                                                <label htmlFor="Keyword" style={{ fontSize: 14, marginRight: 16 }}>Keyword</label>
                                            </div>
                                            <div className="p-col-9">
                                                <input type="text" className="ui-inputtext ui-widget ui-state-default ui-corner-all" id="Keyword" style={{ width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-g-12 ui-lg-6 pl-30 pr-30">
                                        <Button label="Search" className="p-button-info" style={{ height: 36, padding: '0px 30px' }} />
                                    </div>
                                </div>
                                <DataTable value={transactionHistoryData} style={{marginBottom: '20px'}} responsive={true} selectionMode="single">
                                    <Column field="account" header="Account" sortable={true} />
                                    <Column field="date" header="Date" sortable={true} />
                                    <Column field="amount" header="Amount" sortable={true} />
                                    <Column field="invoiceNo" header="Invoice No." sortable={true} />
                                    <Column field="paymentMethod" header="Payment Method" sortable={true} />
                                    <Column field="type" header="Type" sortable={true} />
                                </DataTable>
                            </div>
                        </Panel>
                    </div>
                </div>
    }
}

export default PendingWalletSettlementReport;