import React, {Component} from "react";

class Dashboard extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="pageWrapper">
                Invoice Payment Board
            </div>
        );
    }

}

export default Dashboard;