import React, {Component} from "react";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import swal from 'sweetalert';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import CardTitle from "../shared/Card/Title";
import Card from "../shared/Card";
import {UsuarioService} from "../../service/login/UsuarioService";

class RequestPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchant_key: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            paymentAmount: '',
            invoiceNumber: '',
            comments: '',
            invoiceFile: '',
            type: '1',
            merchants:[],
        }
    }

    componentDidMount() {

        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            merchants: JSON.parse(lstMerchants)
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangeAmount = e => {
        if (this.isAmount(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    isAmount(data) {
        const re = /^[0-9]*\.?[0-9]*$/;
        let istrue = false;

        if (data === '' || re.test(data)) {
            istrue = true;
            return istrue;
        }
        return istrue;
    }

    submitHandler = e => {
        const {
            merchant_key,
            firstName,
            lastName,
            email,
            phone,
            paymentAmount,
            invoiceNumber,
            comments,
            invoiceFile,
            type,
        } = this.state;
        const userName = 'ovaleriano@gmail.com';
        let error = false;
        if (firstName.trim() === '')
            error = true;
        if (email.trim() === '')
            error = true;
        if (paymentAmount.trim() === '')
            error = true;

        if(error){
            swal({
                title: "Error",
                text: "first Name, email and amount are necessary!",
                icon: "error",
            });
        }
        if (!error) {
            swal({
                title: "Are you sure?",
                text: "Are you, send invoice payment!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willSend) => {
                if (willSend) {
                    this.apiHeisenberg = new HeisenbergService();
                    this.apiHeisenberg.postInvoiceCreate({
                        merchant_key: merchant_key,
                        username: userName,
                        type: type,
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        phone: phone,
                        amount: paymentAmount,
                        invoice_number: invoiceNumber,
                        comments: comments,
                    }).then(resp => {
                        if (resp.status === 'success') {
                            this.setState({
                                merchant_key: '',
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: '',
                                paymentAmount: '',
                                invoiceNumber: '',
                                comments: '',
                                invoiceFile: '',
                            });
                            swal({
                                title: "Success",
                                text: "Your invoice was sent!",
                                icon: "success",
                            });
                        } else {
                            swal({title: "ERROR", text: resp["messageResponse"], icon: "error"});
                        }
                    }).catch(error => {
                        swal({title: "ERROR", text: error.response.data.messageResponse, icon: "error"});
                    });
                }
            });
        }
    }

    render() {
        const {
            merchant_key,
            firstName,
            lastName,
            email,
            phone,
            paymentAmount,
            invoiceNumber,
            comments,
            invoiceFile,
            type,
            merchants,
        } = this.state;

        const typeSelectItems = [
            {label: 'InvoiceVerification', value: '1'},
            {label: 'Invoice2Factor', value: '2'}
        ];

        const requestBody = (
            <div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>First Name:*</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={firstName} name='firstName'
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>Last Name:</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={lastName} name='lastName'
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>Email:*</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={email} name='email' placeholder="email@example.com"
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>Phone:</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={phone} name='phone' placeholder="(000) 000-0000"
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>Payment Amount:*</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={paymentAmount} name='paymentAmount' onChange={this.handleChangeAmount}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <label>Invoice Number:</label>
                            </div>
                            <div className="p-col-8">
                                <InputText value={invoiceNumber} name='invoiceNumber'
                                           onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-2">
                        <label>Comments: </label>
                    </div>
                    <div className="p-col-10">
                        <InputTextarea rows={6} cols={40} value={comments} name='comments'
                                       onChange={this.handleChange}/>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-2">
                        <label>Select Invoice File: </label>
                    </div>
                    <div className="p-col-4">
                        <InputText value={invoiceFile} name='invoiceFile' onChange={this.handleChange}/>
                    </div>
                    <div className="p-col-2">
                        <label>Type: </label>
                    </div>
                    <div className="p-col-4">
                        <Dropdown value={type} options={typeSelectItems} style={{width: '100%'}}
                                  onChange={(e) => {this.setState({type: e.value})}}
                                  placeholder="Type"/>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col text-center">
                        <Button label="Submit" className="p-button-success" onClick={this.submitHandler}/>
                    </div>
                </div>
            </div>
        );
        return (
            <Card>
                <CardTitle>Request Payment</CardTitle>
                <div className="p-grid">
                    <label>Enter details below and we will send email with simple instructions on how to
                        pay.</label>
                </div>
                <br/>
                <div className="p-grid">
                    <div className="p-col-2">
                        <label>Select Merchant:* </label>
                    </div>
                    <div className="p-col-4">
                        <Dropdown value={merchant_key} options={merchants} style={{width: '100%'}}
                                  onChange={(e) => {
                                      this.setState({merchant_key: e.value})
                                  }}
                                  placeholder="Select a Merchant"/>
                    </div>
                </div>
                {(merchant_key !== '') && requestBody}
            </Card>
        );
    }

}

export default RequestPayment;